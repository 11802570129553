<ng-container>
  @if (includeSvg) {
    <svg-item
      [type]="'level'"
      [size]="svgSize"
      [fill]="svgFill"
      [class]="'-rotate-90'"
    />
  }

  <span
    #user_content
    [title]="label"
    [class]="{
      'headline': true,
      'hidden': user_content.innerText.length < 1,
    }"
  >
    <ng-content/>
  </span>

  @if (user_content.innerText.length < 1) {
    <span
      [title]="label"
      class="headline"
    >
      {{ label }}
    </span>
  }

  @if (includeSvg) {
    <svg-item
      [type]="'level'"
      [size]="svgSize"
      [fill]="svgFill"
      [class]="'-scale-x-100 rotate-90'"
    />
  }
</ng-container>
