<ng-container>
  @if (isType('arrows')) {
    <svg
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 998.95 956.71"
    >
      <path
        d="M998.95,478.16c-157.47,159.89-312.82,317.63-468.24,475.29-1.58,1.6-4.15,2.21-6.19,3.25-1.25-1.64-3.07-2.91-3.07-4.18-.32-315.39-.51-630.77-.6-946.16,0-2.14,1.59-4.29,2.41-6.36,2.23.5,4.28.32,5.21,1.26,156.15,158.18,312.22,316.44,470.48,476.91Z"/>
      <path
        d="M477.16,478.55C320.14,638.38,164.84,796.14,9.26,953.63c-1.51,1.53-4.08,2.03-6.05,2.96-1.33-1.69-3.09-2.88-3.09-4.08C0,635.45,0,318.39,0,1.65c5.15-3.65,8-.15,10.89,2.64,4.19,4.05,8.24,8.24,12.33,12.39,144.88,147.16,289.76,294.32,434.61,441.5,5.81,5.9,11.41,12,19.33,20.37Z"/>
    </svg>
  } @else if (isType('level')) {
    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.7 355.47">
      <path
        d="M282.46,83.42c-.59.2-1.45.72-2.31.72-91.77.06-183.54.09-275.31.08-1.19,0-2.38-.45-3.96-.77-1.53-9.08-.55-18.12-.72-27.09-.17-9.07-.04-18.15-.04-27.22V1.55C2.2,1.02,3.89.38,5.63.19c2.17-.23,4.38-.08,6.57-.08,86.45,0,172.9-.01,259.34.03,3.7,0,7.5-.64,10.87.81.38.45.6.66.74.91.15.27.3.59.3.88.09,25.34.18,50.68.23,76.01,0,1.23-.25,2.46-.45,3.69-.04.27-.38.49-.79.98Z"/>
      <path
        d="M1.24,216.41c-1.51-8.39-1.52-74.63-.05-83.12,1.02-.19,2.19-.6,3.36-.6,64.8-.03,129.59-.03,194.28-.03.57,1.44,1.2,2.29,1.21,3.14.05,25.96.05,51.92,0,77.88,0,.85-.65,1.7-.96,2.49-7.12.92-186.35,1.15-197.83.24Z"/>
      <path
        d="M.84,272.17c1.37-.37,2.26-.81,3.14-.81,40.08-.04,80.15-.05,120.23-.02.88,0,1.76.42,2.49.6.36.39.61.59.77.86.15.26.27.57.28.86.13,3.75.33,7.49.33,11.24-.03,22.52-.11,45.04-.21,67.56,0,.58-.43,1.16-.86,2.24-1.85.23-3.95.73-6.06.73-37.57.05-75.15.05-112.72,0-2.41,0-4.83-.46-7.08-.69-1.27-7.59-1.49-71.65-.31-82.58Z"/>
    </svg>
  }
</ng-container>
