import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { SvgItemType } from '../../types/types';

@Component({
  selector: 'svg-item',
  standalone: true,
  imports: [],
  templateUrl: './svg-item.component.html',
  styleUrl: './svg-item.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SvgItemComponent implements OnInit, OnChanges {
  @Input({ required: false })
  public type: SvgItemType = 'arrows';

  @Input({ required: false })
  public size: number = 50;

  @Input({ required: false })
  public fill: string = '#000000';

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {
    this._applyProperties();
  }

  public ngOnChanges({ size, fill }: SimpleChanges): void {
    if ((size && !size.firstChange) || (fill && !fill.firstChange)) {
      this._applyProperties();
    }
  }

  protected isType(type: SvgItemType): boolean {
    return this.type === type;
  }

  private _applyProperties(): void {
    const element: HTMLElement = this._self.nativeElement as HTMLElement;

    element.style.setProperty('--svg-size', `${this.size}px`);
    element.style.setProperty('--svg-color', this.fill);
  }
}
